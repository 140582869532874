<template>
  <v-app>
    <div class="main-container">
      <v-flex xs12 sm6 md4>
        <v-card elevation="2" :loading="loading">
          <div class="pa-5">
            <v-card-text class="pa-2">
              <v-container class="mb-3">
                <v-row align="center" justify="center">
                  <v-img
                    src="@/assets/naja_logo.png"
                    max-height="150"
                    max-width="150"
                  ></v-img>
                </v-row>
              </v-container>
              <div v-show="!success">
                <v-card-title class="justify-center">
                  Alterar Senha
                </v-card-title>
                <v-card-subtitle align="center">
                  Defina uma nova senha
                </v-card-subtitle>
                <v-form class="mt-3" @submit.prevent="submit">
                  <button type="submit" class="hide"></button>
                  <v-alert
                    v-show="errors.length > 0"
                    outlined
                    dense
                    type="error"
                    class="mb-6"
                  >
                    {{ errors.join(', ') }}
                  </v-alert>
                  <v-text-field
                    label="Nova senha"
                    autofocus
                    outlined
                    placeholder="Digite sua nova senha"
                    name="password"
                    v-model="form.password"
                  ></v-text-field>
                  <v-text-field
                    label="Confirmar senha"
                    outlined
                    placeholder="Digite sua nova senha"
                    name="password_confirmation"
                    v-model="form.password_confirmation"
                  ></v-text-field>
                </v-form>
              </div>
              <div class="pt-5 text-center" v-show="success">
                <v-icon color="green" size="55">
                  mdi-checkbox-marked-circle
                </v-icon>
                <div class="text-h4 mb-4">Senha alterada</div>
                <div class="text-subtitle-1">
                  Sua senha foi alterada com sucesso
                </div>
              </div>
            </v-card-text>
            <v-card-actions v-show="!success">
              <v-spacer />
              <v-btn @click="submit" color="primary">Enviar</v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-flex>
    </div>
  </v-app>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
export default {
  data() {
    return {
      loading: false,
      errors: [],
      success: false,
      form: {
        token: null,
        password: '',
        password_confirmation: '',
      },
    }
  },

  mounted() {
    this.form.token = this.$route.params.token
  },

  methods: {
    submit() {
      this.errors = []
      this.loading = true

      Api.resetPassword(this.form)
        .then(() => {
          this.success = true
        })
        .catch(error => {
          if (error.response.status == 500) {
            this.errors.push('Erro ao recuperar senha')
          } else if (error.response.status == 404) {
            this.errors.push('Usuário não encontrado')
          } else {
            this.errors.push(error.response.data.error)
          }
          throw error
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
